import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { chunk } from 'lodash'
import { uniq } from 'lodash'
import React, { memo } from 'react'

import { Image, Props as ImageProps } from './Image'

export interface Props {
  categoryName?: string
  className?: string
  images?: ImageProps[]
  title?: string
}

export const Category = memo(function Category({
  categoryName,
  className,
  images,
}: Props) {
  if (!images) {
    return null
  }

  const categories = chunk(images, 5)

  return (
    <>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <Media lessThan="ipadHorizontal">
          <Container row wrap space="between">
            {uniq(images).map((item, index) => {
              return (
                <Image
                  className={className}
                  key={index}
                  {...item}
                  title={`${item.title}<div>${categoryName}</div>`}
                />
              )
            })}
          </Container>
        </Media>

        {categories.map((images, index) => {
          const category = chunk(images, 4)

          return (
            <Media key={index} greaterThanOrEqual="ipadHorizontal">
              <Container row wrap space="between">
                {category.map((item, index) => {
                  return (
                    <Stripe key={index} row wrap space="between">
                      {item.map((e, i) => {
                        return (
                          <Image
                            className={className}
                            key={i}
                            {...e}
                            title={`${e.title}<div>${categoryName}</div>`}
                          />
                        )
                      })}
                    </Stripe>
                  )
                })}
              </Container>
            </Media>
          )
        })}
      </MediaContextProvider>
    </>
  )
})

const Container = styled(FlexBox)`
  @media (max-width: 1023px) {
  }
`

const Stripe = styled(FlexBox)`
  width: calc(50% - 0.75rem);
  &:nth-of-type(2) {
    > div {
      width: 100%;
      padding-bottom: calc(72% + 1.5rem);
    }
  }
`
