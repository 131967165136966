import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { rgba } from 'emotion-rgba'
import React, { memo, useState } from 'react'
import { Gallery as PhotoSwipe } from 'react-photoswipe-gallery'
import Slider from 'react-slick'

import { Category, Props as CategoryProps } from './Category'
import { Filter, Props as FilterProps } from './Filter'

export interface Props {
  description?: string
  filters?: FilterProps[]
  categories?: CategoryProps[]
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  description,
  filters,
  categories,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    variableWidth: true,
  }

  const firstCategory = filters
    ? filters.map((item) => item.category)[0]?.toLocaleLowerCase()
    : null
  const [filteredCategory, setFilteredCategory] = useState(firstCategory)

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {filters && filters.length > 1 ? (
        <>
          <style type="text/css">{mediaStyle}</style>
          <MediaContextProvider>
            <Media lessThan="ipadHorizontal">
              <Carousel {...settings}>
                {filters.map((item, index) => {
                  const category = item.category
                    ? item.category
                        .replace(/[^a-zA-Z]/g, '')
                        .toLocaleLowerCase()
                    : undefined

                  return (
                    <Filter
                      active={filteredCategory === category}
                      key={index}
                      {...item}
                      onClick={(e) => {
                        setFilteredCategory(
                          e.target.getAttribute('data-category'),
                        )
                      }}
                    />
                  )
                })}
              </Carousel>
            </Media>
            <Media greaterThanOrEqual="ipadHorizontal">
              <Filters dial={2} row wrap>
                {filters.map((item, index) => {
                  const category = item.category
                    ? item.category
                        .replace(/[^a-zA-Za-zA-Za-zA-Za-åa-ö-w-я]/g, '')
                        .toLocaleLowerCase()
                    : undefined

                  return (
                    <Filter
                      active={filteredCategory === category}
                      key={index}
                      {...item}
                      onClick={(e) => {
                        setFilteredCategory(
                          e.target.getAttribute('data-category'),
                        )
                      }}
                    />
                  )
                })}
              </Filters>
            </Media>
          </MediaContextProvider>
        </>
      ) : null}

      <Categories row wrap>
        {categories.map((item, index) => {
          const category = item.title
            ? item.title
                .replace(/[^a-zA-Za-zA-Za-åa-ö-w-я]/g, '')
                .toLocaleLowerCase()
            : undefined

          return (
            <PhotoSwipe
              key={index}
              options={{
                barsSize: {
                  top: 150,
                  bottom: 100,
                },
                closeElClasses: '',
                closeOnScroll: false,
                closeOnVerticalDrag: false,
                pinchToClose: false,
                fullscreenEl: false,
                zoomEl: false,
                shareEl: false,
                preloaderEl: false,
                mouseUsed: true,
                tapToToggleControls: false,
                history: false,
                showHideOpacity: true,
                maxSpreadZoom: 1,
                getDoubleTapZoom: function (isMouseClick: any, item: any) {
                  return item.initialZoomLevel
                },
              }}
            >
              <Category
                categoryName={item.title}
                className={
                  filteredCategory === category ? 'visible' : undefined
                }
                {...item}
              />
            </PhotoSwipe>
          )
        })}
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 9rem 10vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 7.5rem 8.454vw 5.625rem;
  }

  @media (max-width: 1023px) {
    padding-right: 0;
    padding-left: 0;
  }

  @media (max-width: 1023px) {
    .pswp__button {
      &.pswp__button--arrow--left,
      &.pswp__button--arrow--right {
        display: none;
      }
    }
  }

  /* OVERRIDE PHOTOSWIPE STYLE */
  .pswp__bg {
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
  }
  .pswp__img--placeholder--blank {
    background: none;
  }
  .pswp--zoom-allowed .pswp__img {
    cursor: default;
  }
  .pswp--touch {
    .pswp__button--arrow--left,
    .pswp__button--arrow--right {
      visibility: visible;
    }
  }
  .pswp__caption {
    display: flex;
    min-height: 0;
    bottom: 46px;
    background: none;
    .pswp__caption__center {
      max-width: none;
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 1rem;
      line-height: 1.75rem;
      text-align: center;
      > div {
        width: 100%;
        color: ${({ theme }) => theme.colors.variants.neutralLight4};
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-size: 2.5rem;
        line-height: 3rem;
        position: fixed;
        top: 4.5rem;
        left: 0;

        @media (max-width: 1023px) {
          font-size: 2.1875rem;
          line-height: 2.625rem;
          top: 5.625rem;
        }
      }
    }
  }
  .pswp__top-bar {
    height: auto;
    background: none;
  }
  .pswp__button {
    width: 84px;
    height: 84px;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 1;
    &.pswp__button--arrow--left,
    &.pswp__button--arrow--right {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.634' height='31.571' viewBox='0 0 24.634 31.571'%0A%3E%3Cg transform='translate(-707.963 -752.5)'%3E%3Cpath d='M707,775l11.717,12,11.847-12' transform='translate(1.5 -4)' fill='none' stroke='%23fff' stroke-width='1.5' /%3E%3Cline y1='30' transform='translate(720.5 752.5)' fill='none' stroke='%23fff' stroke-width='1.5' /%3E%3C/g%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      border: 1px solid ${rgba(theme.colors.variants.neutralLight4, 0.15)};
      border-radius: 50%;
      margin-top: -42px;
      transform: translateY(-50%);
      transition: 0.3s ease-in-out;
      &:before {
        display: none;
        background-color: transparent;
      }
      &:hover {
        background-color: ${rgba(theme.colors.variants.neutralLight4, 0.1)};
        border-color: transparent;
      }
    }
    &.pswp__button--close {
      width: 40px;
      height: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='1' stroke-linecap='round' stroke-linejoin='round' %0A%3E%3Cline x1='18' y1='6' x2='6' y2='18' /%3E%3Cline x1='6' y1='6' x2='18' y2='18' /%3E%3C/svg%3E%0A");
      background-size: contain;
      position: absolute;
      top: 30px;
      left: 48px;

      @media (max-width: 1439px) {
        left: 2.1875rem;
      }
    }
    &.pswp__button--arrow--left {
      left: 6.944vw;
      transform: rotate(90deg);

      @media (max-width: 1439px) {
        left: 2.1875rem;
      }
    }
    &.pswp__button--arrow--right {
      right: 6.944vw;
      transform: rotate(-90deg);

      @media (max-width: 1439px) {
        right: 2.1875rem;
      }
    }
  }
  .pswp__counter {
    height: auto;
    color: ${({ theme }) => theme.colors.variants.neutralLight1};
    font-family: ${({ theme }) => theme.fontFamily.heading};
    font-size: 0.875rem;
    line-height: 0.9375rem;
    opacity: 1;
    position: fixed;
    top: auto;
    bottom: 28px;
    right: 0;
    text-align: center;
  }

  @media (-webkit-min-device-pixel-ratio: 1.1),
    (-webkit-min-device-pixel-ratio: 1.09375),
    (min-resolution: 1.1dppx),
    (min-resolution: 105dpi) {
    .pswp--svg .pswp__button--close:before,
    .pswp--svg .pswp__button--arrow--left:before,
    .pswp--svg .pswp__button--arrow--right:before {
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQgAAABYCAQAAACjBqE3AAAB6klEQVR4Ae3bsWpUQRTG8YkkanwCa7GzVotsI/gEgk9h4Vu4ySLYmMYgbJrc3lrwZbJwC0FMt4j7F6Y4oIZrsXtgxvx/1c0ufEX4cnbmLCmSJEmSJEmSJEmSJP3XCBPvbJU+8doWmDFwyZpLBmYlNJebz0KwzykwsuSYJSNwykEJreV2BaBMaLIQZ2xYcFgqDlmw4ayE/FwL0dDk4Qh4W37DAjgqIT+3HRbigjH+iikVdxgZStgyN0Su2sXIeTwTT+esdpcbIlfNAuZ/TxresG4zV8kYWSZNiKUTokMMSWeIwTNEn4fK2TW3gRNgVkJLuVksROA9G+bEvoATNlBCa7nZXEwdxEZxzpKRKFh+bsv8LmPFmhX1OwfIz81jIRJQ5eeqG9B+riRJkiRJkiRJkiRJkiRJkiRJUkvA/8RQoEpKlJWINFkJ62AlrEP/mNBibnv2yz/A3t7Uq3LcpoxP8COjC1T5vxoAD5VdoEqdDrd5QuW1swtUSaueh3zkiuBiqgtA2OlkeMcP/uDqugsJdbjHF65VdPMKwS0+WQc/MgKvrIOHysB9vgPwk8+85hmPbnQdvHZyDMAFD7L3EOpgMcVdvnHFS0/vlatrXvCVx0U9gt3fxvnA0/hB4nmRJEmSJEmSJEmSJGmHfgFLaDPoMu5xWwAAAABJRU5ErkJggg==) !important;
    }
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.25rem;
  margin-bottom: 0.75rem;

  @media (max-width: 1199px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  @media (max-width: 1023px) {
    padding: 0 8.454vw;
  }
`

const Description = styled.div`
  max-width: 680px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin: auto;

  @media (max-width: 1023px) {
    padding: 0 8.454vw;
  }
`

const Filters = styled(FlexBox)`
  margin: 2.0625rem 0 2.875rem;
`

const Carousel = styled(Slider)`
  margin: 3.75rem 0 3rem;
  padding: 0 calc(8.454vw - 0.3125rem);

  .slick-list {
    overflow: visible;
  }

  .slick-track {
    display: flex;
  }
`

const Categories = styled(FlexBox)`
  .fresnel-container {
    width: 100%;
    &:nth-of-type(odd) {
      > div {
        div {
          &:nth-of-type(1) {
            order: 2;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    padding: 0 8.454vw;
  }
`
