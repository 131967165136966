import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  category: string
  active?: boolean
  label: string
  onClick?: (e: any) => void
}

export const Filter = memo(function Filter({
  active = false,
  category,
  label,
  onClick,
}: Props) {
  if (!label) {
    return null
  }
  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {label}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.375rem;
  line-height: 1.875rem;
  margin: 2.5rem 0.375rem 0;
  padding: 0.6875rem 1rem 0.875rem;
  transition: 0.3s ease-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.primaryLight1};
  }

  @media (max-width: 1023px) {
    font-size: 0.875rem;
    line-height: 1.1875rem;
    margin: 0 0.375rem 0;
    padding: 0.4375rem 0.625rem 0.625rem;
  }
`
